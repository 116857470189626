
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import {useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';

function NotificationsUpdate() {

  let char_id = useLocation().pathname.split("/")[2]
  let notificationId = useLocation().pathname.split("/")[4]

  const [notification, setNotification] = useState({
    text:"",
    lan:"",
    len:""
  });
  
  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/dailyGetCoordinates/' + notificationId+char_id, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        let par = JSON.parse(res.data).split(" ")
        console.log(JSON.parse(res.data))
        if(par.length >= 2){
          setNotification(
            {
              text:"",
              lan:par[0],
              len:par[1]
            }
          )
        }
      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.put(url + "/dailyUppdateCoordinates/" + notificationId + char_id, {text: notification.lan + ' ' + notification.len}, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate(-1)
    } catch (err) {

    }
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
          <button onClick={handleClick} className='AddEvent' style={{backgroundColor:"#0F6C00", color:"#fff"}}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>
        <h2>Місце знаходження(Для внесення найдіть це місце на карті, натисніть ПКМ та скопіюйте координати, після чого внесіть перше число в перше віконце а інше у друге)</h2>
        <input onChange={HandleChange} name="lan"  value={notification.lan}></input>
        <input onChange={HandleChange} name="len"  value={notification.len}></input>
      </div>
    </div>
  )
}

export default NotificationsUpdate
