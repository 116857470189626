
import axios from 'axios';
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import url from '../../url';

function NotificationsUpdate() {

  let char_id = useLocation().pathname.split("/")[2]
  let notificationId = useLocation().pathname.split("/")[4]

  const [notification, setNotification] = useState({
    link: "",
  });

  useEffect(() => {
    const fecthAllnotifications = async () => {
      try {
        const res = await axios.get(url + '/dailyGetLink/' + notificationId + char_id, {
          headers: {
            email: Cookies.get('admingromadia.in.email'),
            password: Cookies.get('admingromadia.in.password')
          }
        })
        let par = JSON.parse(res.data)
        setNotification(
          {
            link: par,
          }
        )

      } catch (err) {
        console.log(err)
      }
    }
    fecthAllnotifications()
  }, [notificationId])

  const HandleChange = (e) => {
    setNotification(prev => ({ ...prev, [e.target.name]: e.target.value }))
  }
  const navigate = useNavigate();

  const handleClick = async e => {
    e.preventDefault()
    try {
      await axios.put(url + "/dailyUppdateLink/" + notificationId + char_id, { link: notification.link }, {
        headers: {
          email: Cookies.get('admingromadia.in.email'),
          password: Cookies.get('admingromadia.in.password')
        }
      });
      navigate(-1)
    } catch (err) {

    }
  }
  return (
    <div className='DataPage'>
      <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
        <button onClick={handleClick} className='AddEvent' style={{ backgroundColor: "#0F6C00", color: "#fff" }}>Зберегти</button>
      </div>
      <div className='ImputWrapper'>
        <h2>Посилання:</h2>
        <input onChange={HandleChange} name="link" value={notification.link}></input>
      </div>
    </div>
  )
}

export default NotificationsUpdate
