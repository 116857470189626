import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom';
import "../styles/options.css"
import axios from 'axios';
import Cookies from 'js-cookie';
import url from '../url';

function Options() {
    const [petition, setPetition] = useState(false);
    const [verification, setVerification] = useState(false);
    const [userproblems, setUserproblems] = useState(false);
    const [dailyPages, setDailyPages] = useState(null);
    const [cnap, setCnap] = useState(false);

    const fecthAllCnapp = async (e) => {
        try {
            const res = await axios.get(url + '/AllCAnp_Online', {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })
            const currentDate = new Date();

            for (let i = 0; i < res.data.length; i++) {
                if (new Date(res.data[i].date) > currentDate) {
                    setCnap(true)
                    return
                }
            }
        } catch (err) {
            console.log(err)
        }
    }



    const fecthAllverification = async () => {
        try {
            const res = await axios.get(url + '/getAllVerify', {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })

            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].images.split(' ').length > 1) {
                    setVerification(true)
                    return
                }
            }

        } catch (err) {
            console.log(err)
        }
    }

    const fecthAllpetition = async () => {
        try {
            const res = await axios.get(url + '/getpetitions', {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })

            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].answer == null && res.data[i].status == null) {
                    setPetition(true)
                    return
                }
            }

        } catch (err) {
            console.log(err)
        }
    }

    const fecthUsersProblems = async () => {
        try {
            const res = await axios.get(url + '/AllProblems', {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })

            for (let i = 0; i < res.data.length; i++) {
                if (res.data[i].answer == null) {
                    setUserproblems(true)
                    return
                }
            }

        } catch (err) {
            console.log(err)
        }
    }

    const fecthDailyPages = async () => {
        try {
            const res = await axios.get(url + '/daily_pages', {
                headers: {
                    email: Cookies.get('admingromadia.in.email'),
                    password: Cookies.get('admingromadia.in.password')
                }
            })


            setDailyPages(res.data)

            return


        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fecthAllCnapp();
        fecthAllpetition();
        fecthUsersProblems();
        fecthAllverification();
        fecthDailyPages();
    }, [])

    return (
        <div className='MainBlock'>
            <NavLink to='/'>
                <div className='OptionsLink' style={verification ? { backgroundColor: "#850000" } : {}}>База користувачів</div>
            </NavLink>
            <NavLink to='/Notifications'>
                <div className='OptionsLink'>Сповіщення</div>
            </NavLink>
            <NavLink to='/NewsPodii'>
                <div className='OptionsLink'>Новини та події</div>
            </NavLink>
            <NavLink to='/CNAP' >
                <div className='OptionsLink' style={cnap ? { backgroundColor: "#850000" } : {}}>ЦНАП</div>
            </NavLink>
            <NavLink to='/Poll'>
                <div className='OptionsLink'>Опитування</div>
            </NavLink>
            <NavLink to='/Problem'>
                <div className='OptionsLink' style={userproblems ? { backgroundColor: "#850000" } : {}}>Повідомлення громадян</div>
            </NavLink>
            <NavLink to='/Petition'>
                <div className='OptionsLink' style={petition ? { backgroundColor: "#850000" } : {}}>Петиції</div>
            </NavLink>
            {dailyPages?.map(data =>(
                <NavLink key={data.id} to={'/info/'+data.char_id}>
                    <div className='OptionsLink'>{data.name}</div>
                </NavLink>
            ))

            }
            {/* <NavLink to='/Poslugi'>
                <div className='OptionsLink'>Послуги</div>
            </NavLink>
            <NavLink to='/Social'>
                <div className='OptionsLink'>Соціальна допомога</div>
            </NavLink>
            <NavLink to='/Turism'>
                <div className='OptionsLink'>Туристичні місця</div>
            </NavLink>
*/}
            {/* <NavLink to='/Daily'>
                <div className='OptionsLink'>ДовідникEX</div>
            </NavLink>  */}
        </div>
    )
}

export default Options
